import React from 'react'
import {
  Container,
  Box,
  Text,
  Flex,
  Heading,
  useColorModeValue,
  Stack,
  Center,
} from '@chakra-ui/react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import Newsletter from '../components/newsletter-test'
import Signup from '../icons/signup'
import Seo from '../components/seo'

const IndexPage = () => {
  const headingColor = useColorModeValue(`headingColor`, `dark.headingColor`)

  return (
    <Layout>
      <Seo
        title="Join The Mud Club to Win Pies"
        description="Sign up to the Mud Foods newsletter for special offers and a chance to win free pies in our monthly draw!"
      />
      <Container py={10}>
        <Flex gap="10" flexWrap={['wrap-reverse', 'wrap-reverse', 'nowrap']}>
          <Box>
            <Stack spacing={4}>
              <Heading as="h1" color={headingColor}>
                {'Join the (free!) Mud Club'}
              </Heading>

              <Text>
                If you’d like to hear about our latest offers and keep in touch
                with news from the pie world, just sign up here for the Mud Club
                newsletter. Easy as pie!
              </Text>
              <Text as="b" fontSize="xl">
                Get 10% off your first order
                <Text fontSize="md">
                  {' '}
                  and a chance to win free pies in our monthly draw!
                </Text>
              </Text>
              <Text as="em" fontSize="sm">
                “I was absolutely surprised, having just signed up for your
                online newsletter, to hear that I had won a dozen pies in your
                monthly draw.  It almost feels like winning the Lottery, but
                hopefully that’s next month!!!”
                <Text textAlign="right" fontSize="sm">
                  Ian, Midhurst
                </Text>
              </Text>
              <Newsletter colorScheme="brand.green" showLabel />
              <Center>
                <Box maxW={250}>
                  <Signup />
                </Box>
              </Center>
            </Stack>
          </Box>
          <Box p={0} data-name="product-image-box">
            <StaticImage
              src="../images/newsletter/newsletter-signup.jpg"
              alt="Pies by email"
              layout="constrained"
              formats={['auto', 'webp']}
              // width={"100%"}
              placeholder="blurred"
            />
          </Box>
        </Flex>
      </Container>
    </Layout>
  )
}

export default IndexPage
