import React, { useState, useCallback } from 'react'
import fetch from 'unfetch'
import { navigate } from 'gatsby'
import { useLoads } from 'react-loads'
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Text,
  Container,
  Link,
  Box,
  Stack,
  HStack,
  Checkbox,
  Flex,
} from '@chakra-ui/react'
import 'react-phone-number-input/style.css'
import PhoneInput, {
  isValidPhoneNumber,
} from 'react-phone-number-input'
import { ErrorHandling } from '../utils/error'



const ContactForm = ({colorScheme}) => {
  const form = React.createRef() as React.RefObject<HTMLFormElement>
  const [attempts, setAttempts] = useState(0)
  const [value, setValue] = useState('')
  const [processingMessage, setProcessingMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [toggleCheck, setToggleCheck] = useState(false)


  function wait(ms = 0) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms)
    })
  }

  const handleSubscribe = useCallback(
    async (email: any, phone: any, toggleCheck: boolean) => {
      setAttempts(attempts + 1)
      setLoading(true)
      setProcessingMessage('Sending')

      const res = await fetch(
        `${process.env.GATSBY_SERVERLESS_BASE}/whatsapp-klaviyo-subscribe`,
        {
          method: 'POST',
          body: JSON.stringify({
            email,
            phone,
            acceptsWhatsapp: toggleCheck
          }),
        }
      )
      try {
        const response = await res.json()

        if (response.error) {
          throw new Error(response.error)
        } else {
      
          setProcessingMessage('Thank you for subscribing')
          await wait(2000)
          setLoading(false)

        }
      } catch (err) {
        throw err
      }
    },
    [attempts]
  )
  const { error, isRejected, isPending, isReloading, load } = useLoads(
    'handleSubscribe',
    handleSubscribe as any,
    {
      defer: true,
    }
  )

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    
    const { email, phone } =
      form!.current!.elements
    load(
      email.value,
      phone.value,
      toggleCheck
    )
  }
  return (

      <form onSubmit={(e) => handleSubmit(e)} ref={form}>
        <Stack spacing={4}>

          {(isPending || isReloading) && <span>Loading</span>}

          {isRejected && <ErrorHandling error={error.message} />}

            

         
            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="email" fontWeight="600">Email (required)</FormLabel>

              <Input
                type="text"
                variant="filled"
                name="email"
                id="email"
                placeholder={'your@email.com'}
                // _placeholder={{ opacity: 1, color: 'gray.800' }}
              />
            </FormControl>

           
    {/* <HStack alignItems="baseline"> */}
      <Box>
      
      </Box>
            <FormControl mb={4}>
              <FormLabel htmlFor="phone" fontWeight="600" fontSize="sm">Whatsapp/SMS (optional)<Text fontWeight={"400"} fontSize="sm">{`(...don't miss a crumb! No spam, just hand-picked updates that matter to you)`}</Text></FormLabel>
              

              <Input
                as={PhoneInput}
                variant="filled"
                _placeholder={{ opacity: 0.5, color: 'gray.400'}}
                name="phone"
                id="phone"
                placeholder={'Your phone number'}
                value={value}
                international={true}
                defaultCountry="GB"
                withCountryCallingCode={true}
                onChange={() => setValue}
                error={
                  isValidPhoneNumber(value)
                      ? undefined
                      : 'Invalid phone number'
                    
                }
              />

            </FormControl>
            {/* <FormControl mb={4}>
            <FormLabel htmlFor="acceptsWhatsapp">Send me messages via</FormLabel>
            <HStack><Text><strong>WhatsApp</strong></Text>
            <Checkbox
              name="acceptsWhatsapp"
              id="acceptsWhatsapp"
              onChange={() => setToggleCheck(toggleCheck ? false : true)}
            />
            
            </HStack>
          </FormControl> */}
        {/* </HStack> */}

        <Flex alignItems="center">
            <Button
              type="submit"
              colorScheme={colorScheme}
              pr={4}
              pl={4}
              mt={1}
              width="100%"
            >
              <Text>{loading ? processingMessage : 'Subscribe'}</Text>
            </Button>
          </Flex>
         
         <Text fontSize="sm">If you ever want to step back from the pie party, simply click the unsubscriibe link. For all the fine print, check out our <Link variant="inline" href="/privacy-policy">Privacy Policy</Link> & <Link variant="inline"  href="/terms-of-service">Terms</Link>.</Text>
        
        </Stack>
      </form>

  )
}


const ContactFormOld = ({ showLabel = false, colorScheme }) => {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [error, setError] = useState('')
  const [previousError, setPreviousError] = useState('')
  const [loading, setLoading] = useState()
  const [processingMessage, setProcessingMessage] = useState('')
  const toast = useToast()
  const formToastId = 'form-warning-toast'

  function wait(ms = 0) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms)
    })
  }

  function handleSubmit(event: { preventDefault: () => void }) {
    event.preventDefault()
    console.log('submitted')
    setLoading(true)
    setProcessingMessage('Sending message')

    // var details
    // if(phone != ''){
    //   details = {
    //     g: 'RN9wEV',
    //     email: email,
    //     $phone_number: phone,
    //     sms_consent: true
    //   }
    // }
    // else{
    //   details = {
    //     g: 'RN9wEV',
    //     email: email
    //   }
    // }

    // const formBody = Object.keys(details)
    //   .map(
    //     (key) =>
    //       encodeURIComponent(key) + '=' + encodeURIComponent(details[key])
    //   )
    //   .join('&')

    // const res = await fetch(
    //   'https://manage.kmail-lists.com/ajax/subscriptions/subscribe',
    //   {
    //     method: 'POST',
    //     headers: {
    //       'content-type': 'application/x-www-form-urlencoded',
    //     },
    //     body: formBody,
    //   }
    // )
    // const text = JSON.parse(await res.text())

    const options = {
      method: 'POST',
      headers: { revision: '2023-10-15', 'content-type': 'application/json' },
      body: JSON.stringify({
        data: {
          type: 'subscription',
          attributes: {
            custom_source: 'website newsletter signup page',
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email: email,
                  phone_number: phone,
                },
                properties: {
                  'Whatsapp consent': phone,
                },
              },
            },
          },
          relationships: { list: { data: { type: 'list', id: 'Xn292M' } } },
        },
      }),
    }

    fetch(
      'https://a.klaviyo.com/client/subscriptions/?company_id=Ykscnm',
      options
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          // if (response.text.errors.length) setError(response.text.errors[0])
          setProcessingMessage('Sending failed')
          wait(2000)
          setLoading(false)
        } else {
          setProcessingMessage('Thank you for subscribing')
          wait(2000)
          setLoading(false)
        }
        // if (response.text.data.is_subscribed) {
        //   setError("You're already subscribed!")
        //   setProcessingMessage("You're already subscribed!")
        //   wait(2000)
        //   setLoading(false)
        // } else {
        //   setProcessingMessage('Thank you for subscribing')
        //   wait(2000)
        //   setLoading(false)
        // }
      })
      .catch((err) => console.error(err))
  }

  function showToast(error: React.SetStateAction<string>) {
    if (!toast.isActive(formToastId)) {
      toast({
        id: formToastId,
        title: 'Something went wrong.',
        description: error,
        status: 'error',
        isClosable: true,
      })
    }
    setPreviousError(error)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <FormControl colorScheme={colorScheme} isRequired>
          {showLabel && (
            <FormLabel htmlFor="k_id_email3">
              <strong>Email</strong>
            </FormLabel>
          )}
          {/* <Input type="hidden" name="g" value="RN9wEV" /> */}

          <Input
            variant="filled"
            type="email"
            name="email"
            id="k_id_email3"
            placeholder="your@email.com"
            borderRadius="0"
            onBlur={(event) => setEmail(event.target.value)}
            border={'2px'}
            borderColor={'brand.asparagus.500'}
            background={'white.400'}
            _placeholder={{ opacity: 1, color: 'gray.500' }}
          />
        </FormControl>

        <Text fontSize="sm" mt="1.4rem">
          From time to time, we would like to notify you about extra special
          events and offers via WhatsApp or SMS. Just enter your mobile number
          below.
        </Text>

        <FormControl colorScheme={colorScheme} mt="1rem">
          {showLabel && (
            <FormLabel htmlFor="k_id_phone">
              <strong>Your phone</strong> (optional)
            </FormLabel>
          )}

          <Input
            variant="filled"
            type="phone_number"
            name="phone_number"
            id="k_id_phone"
            placeholder="079000000"
            borderRadius="0"
            onBlur={(event) => setPhone(event.target.value)}
            border={'2px'}
            borderColor={'brand.asparagus.500'}
            background={'white.400'}
            _placeholder={{ opacity: 1, color: 'gray.500' }}
          />
        </FormControl>

        {error !== previousError && showToast(error)}
        <Button
          type="submit"
          colorScheme={colorScheme}
          pr={4}
          pl={4}
          mt="1rem"
          width="100%"
        >
          <Text>{loading ? processingMessage : 'Subscribe'}</Text>
        </Button>
      </Box>
    </form>
  )
}

export default ContactForm
